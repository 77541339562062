import React from "react"
import Menu from "../components/menu"
import { Helmet } from "react-helmet"

export default ({ title, description, children, dir, menu }) => {
  return (
    <>
      <Helmet>
        <title>{title} - Voice Score </title>
        <meta name="description" content={description} />
      </Helmet>

      <div
        className={"container " + title.toLowerCase() + ` ${dir}`}
        id="App"
        style={{ direction: dir }}
      >
        <Menu currentPage={"current Page"} name={"Voice Score"} menu={menu} />

        {children}
      </div>
    </>
  )
}
