import React from "react"

const MenuItem = props => {
  return props.external ? (
    <a
      href={props.to}
      className={"page-link" + props.className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.name}
    </a>
  ) : (
    <a href={props.to} className={"page-link" + props.className}>
      {props.name}
    </a>
  )
}

export default MenuItem
